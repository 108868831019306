import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1280.000000 720.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,720.000000) scale(0.100000,-0.100000)">
<path d="M0 3600 l0 -3600 6400 0 6400 0 0 3600 0 3600 -6400 0 -6400 0 0
-3600z m8370 2715 c340 -56 649 -214 895 -460 94 -93 218 -246 247 -305 7 -14
30 -54 50 -90 75 -135 143 -341 174 -530 18 -113 21 -341 5 -460 -11 -77 -15
-88 -46 -117 -41 -39 -92 -44 -142 -13 -44 26 -56 74 -43 171 40 310 -39 665
-208 929 -151 235 -326 393 -567 510 -188 92 -363 138 -555 147 -132 5 -168
14 -199 47 -51 55 -28 158 40 184 42 15 212 9 349 -13z m-74 -469 c164 -29
301 -81 427 -162 270 -173 451 -426 528 -739 25 -100 36 -323 21 -419 -17
-106 -75 -153 -155 -126 -53 17 -82 54 -81 103 6 276 5 306 -11 377 -45 192
-121 329 -266 480 -142 147 -349 239 -591 263 -177 17 -208 37 -208 134 0 27
8 43 34 69 34 34 34 34 128 34 51 0 129 -6 174 -14z m-58 -472 c226 -45 437
-222 515 -431 42 -110 49 -152 49 -264 0 -123 -16 -170 -65 -196 -46 -24 -115
-12 -144 24 -21 25 -23 39 -23 133 0 202 -61 332 -201 429 -74 51 -130 71
-229 81 -100 10 -131 22 -153 60 -36 60 -11 138 52 164 47 20 104 20 199 0z
m-433 -178 c113 -34 194 -77 262 -140 108 -98 132 -175 140 -443 l6 -183 -301
0 -300 0 -4 158 c-2 130 -6 163 -21 185 -37 57 -127 47 -165 -18 -21 -37 -23
-50 -19 -155 5 -177 28 -207 285 -364 259 -157 431 -303 491 -415 106 -199
121 -675 27 -876 -60 -127 -231 -236 -441 -280 -106 -23 -322 -23 -430 -1
-224 45 -396 163 -464 316 -38 88 -52 187 -58 403 l-5 207 301 0 301 0 0 -190
c0 -218 10 -280 48 -311 22 -17 37 -20 76 -16 92 8 121 74 113 252 -9 207 -24
226 -327 431 -361 243 -417 297 -482 459 -48 120 -62 356 -33 550 38 257 200
404 505 459 14 2 111 3 215 2 173 -3 198 -6 280 -30z m-2709 -253 c18 -125 43
-289 54 -363 11 -74 27 -180 35 -235 8 -55 33 -224 56 -375 22 -151 47 -318
54 -370 16 -110 39 -264 60 -400 8 -52 26 -171 40 -265 14 -93 27 -185 30
-202 l6 -33 -329 0 c-261 0 -331 3 -335 13 -3 7 -10 96 -17 197 -6 102 -14
195 -16 208 -4 21 -8 22 -117 22 l-113 0 -19 -220 -18 -220 -328 0 -329 0 0
58 c0 32 18 195 40 363 23 167 47 354 55 414 8 61 24 180 35 265 11 85 27 205
35 265 23 179 73 561 110 836 19 142 35 262 35 268 0 12 918 21 934 9 5 -4 24
-110 42 -235z m1507 237 c16 0 17 -17 15 -247 l-3 -248 -212 -3 -213 -2 0
-235 0 -235 200 0 200 0 0 -235 0 -235 -200 0 -200 0 2 -272 3 -273 230 -2
230 -2 3 -245 2 -246 -560 0 -560 0 0 1243 0 1242 522 -2 c288 -2 531 -3 541
-3z m247 -2749 c43 -22 60 -53 60 -108 l0 -43 -65 0 -65 0 0 35 c0 36 -22 50
-34 20 -11 -29 13 -63 78 -109 82 -58 96 -84 96 -176 0 -87 -9 -106 -63 -131
-55 -24 -120 -24 -173 1 -48 24 -63 52 -66 125 l-3 50 65 0 65 0 -3 -32 c-4
-40 13 -74 33 -67 10 4 15 20 15 44 0 41 -26 71 -110 126 -47 31 -70 77 -70
139 0 61 23 108 63 128 45 22 134 21 177 -2z m713 5 c47 -19 64 -44 72 -103
l7 -53 -66 0 -66 0 0 35 c0 28 -4 35 -20 35 -16 0 -20 -7 -20 -30 0 -31 8 -40
89 -102 75 -56 86 -78 86 -168 0 -64 -4 -82 -20 -100 -28 -32 -76 -50 -132
-50 -97 0 -153 56 -153 153 l0 47 64 0 65 0 3 -52 c2 -39 7 -53 18 -53 11 0
15 12 15 50 l0 49 -64 45 c-83 58 -101 88 -101 171 0 75 15 104 64 126 40 17
118 18 159 0z m-3453 -3 c1 -5 18 -124 39 -265 l38 -258 -68 0 -68 0 -7 50
c-6 44 -9 50 -30 50 -20 0 -23 -5 -26 -47 l-3 -48 -72 -3 c-40 -2 -73 -2 -73
0 0 2 16 120 35 263 19 143 35 261 35 262 0 7 200 2 200 -4z m280 -88 c0 -78
3 -95 15 -95 12 0 15 17 15 95 l0 95 70 0 70 0 0 -265 0 -265 -70 0 -70 0 0
110 c0 90 -3 110 -15 110 -11 0 -15 -21 -17 -107 l-3 -108 -67 -3 -67 -3 -3
266 -3 265 73 0 72 0 0 -95z m449 33 c6 -35 14 -84 17 -111 4 -26 8 -46 10
-45 1 2 8 52 15 111 l12 107 94 0 93 0 -2 -262 -3 -263 -57 -3 -58 -3 -2 103
c-2 101 -4 143 -8 163 -2 6 -10 -51 -19 -125 l-16 -135 -47 -3 c-54 -3 -46
-20 -70 158 l-13 95 -5 -125 -5 -125 -57 -3 -57 -3 -3 266 -3 265 86 0 87 0
11 -62z m565 40 c3 -13 12 -70 21 -128 8 -58 24 -162 35 -233 11 -70 20 -132
20 -137 0 -6 -31 -10 -70 -10 -70 0 -70 0 -70 28 0 51 -11 73 -34 70 -17 -2
-22 -11 -24 -50 l-3 -48 -70 0 -69 0 0 30 c0 16 9 92 19 167 10 76 25 182 32
236 l13 97 98 0 c93 0 98 -1 102 -22z m417 -16 l29 -32 0 -196 c0 -121 -4
-203 -11 -216 -19 -35 -70 -48 -189 -48 l-110 0 0 266 0 266 126 -3 126 -4 29
-33z m347 -14 l-3 -53 -42 -3 -43 -3 0 -44 c0 -43 0 -44 38 -47 l37 -3 3 -52
3 -53 -40 0 -41 0 0 -55 0 -55 45 0 45 0 0 -55 0 -55 -115 0 -115 0 0 265 0
265 116 0 115 0 -3 -52z m328 5 c3 -27 21 -142 39 -258 18 -115 29 -214 25
-218 -4 -4 -36 -7 -71 -5 l-64 3 -5 45 c-4 36 -9 46 -26 48 -19 3 -22 -3 -28
-47 l-7 -51 -69 0 -70 0 5 38 c18 130 46 344 51 382 3 25 8 60 11 78 l5 32 99
0 98 0 7 -47z m600 23 c3 -12 11 -59 18 -102 l12 -79 18 103 18 102 63 0 63 0
-15 -52 c-8 -29 -30 -104 -49 -167 -29 -96 -34 -129 -34 -212 l0 -99 -64 0
-64 0 -4 118 c-4 99 -11 136 -43 242 -57 187 -58 170 14 170 55 0 61 -2 67
-24z m766 -153 c-4 -183 2 -263 19 -263 5 0 9 90 9 220 l0 220 71 0 70 0 -3
-236 -3 -236 -32 -28 c-58 -52 -168 -52 -225 -1 -40 36 -47 78 -47 314 l-1
187 73 0 72 0 -3 -177z m503 161 c41 -19 60 -61 59 -133 -1 -44 -6 -58 -23
-73 l-23 -18 26 -22 c22 -19 28 -34 32 -88 13 -155 -14 -180 -198 -180 l-118
0 0 265 0 265 106 0 c76 0 115 -5 139 -16z"/>
<path d="M4603 4505 c-7 -55 -24 -185 -37 -290 -33 -253 -71 -614 -65 -619 10
-11 203 -8 207 2 2 6 -3 73 -12 149 -8 76 -27 273 -41 438 -14 165 -29 327
-33 360 l-7 60 -12 -100z"/>
<path d="M4007 2248 c-3 -18 -9 -59 -13 -90 -6 -55 -5 -58 16 -58 21 0 22 2
15 88 -4 48 -9 88 -10 90 -1 2 -5 -12 -8 -30z"/>
<path d="M5294 2197 c-9 -94 -9 -98 10 -95 17 3 19 10 17 63 -1 33 -6 76 -10
95 -6 29 -9 18 -17 -63z"/>
<path d="M5680 2179 c0 -132 3 -170 13 -167 9 4 13 48 15 165 2 136 0 162 -13
167 -13 5 -15 -16 -15 -165z"/>
<path d="M6387 2235 c-4 -33 -10 -77 -13 -97 -5 -34 -3 -38 15 -38 23 0 25 19
12 135 l-7 60 -7 -60z"/>
<path d="M8262 2296 c3 -62 17 -76 32 -33 11 31 -1 78 -22 85 -10 3 -12 -9
-10 -52z"/>
<path d="M8260 2080 c0 -53 3 -70 14 -70 18 0 26 22 26 74 0 39 -13 66 -32 66
-4 0 -8 -31 -8 -70z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
